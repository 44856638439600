import { useContext, useMemo } from 'react'

import { OrderContext } from './provider'
import * as oUtils from './utils'

// Important: all functions must support undefined order!
const useOrder = () => {
  const { order, refetch } = useContext(OrderContext)

  const parsedPayments = useMemo(
    () => oUtils.parsePayments(order),
    [order?.payments],
  )

  const parsedSupplierOrders = useMemo(
    () => oUtils.parseSupplierOrders(order),
    [order?.designs],
  )

  const parsedDesigns = useMemo(
    () => oUtils.parseDesigns(order),
    [order?.designs],
  )

  const parsedAmountsDue = useMemo(
    () => oUtils.parseAmountsDue(order),
    [
      order?.designs,
      order?.payments,
      order?.nobiliaSamples,
      order?.discounts,
      order?.taxRate,
    ],
  )

  const isAddOnOrder = useMemo(
    () => oUtils.isAddOnOrder(order),
    [order?.metadata],
  )

  return {
    ...parsedAmountsDue,
    ...parsedDesigns,
    ...parsedPayments,
    ...parsedSupplierOrders,
    order,
    isAddOnOrder,
    refetch,
  }
}

export default useOrder
