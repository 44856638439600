import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import FormHeader from 'components/shared/header-form'
import Loader from 'components/shared/loader'
import DesignDeposit from 'components/shared/project/payment/versions/design-deposit'
import OtherPayment from 'components/shared/project/payment/versions/other-payment'
import ProjectDeposit from 'components/shared/project/payment/versions/project-deposit'
import usePublicPayment from 'context/payment/use-public'
import fireEvent from 'context/tracking/events'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import ThankYou from 'design-system/containers/thank-you'
import {
  Payment,
  PaymentMethodType,
  PaymentSource,
  PaymentType,
} from 'types/payment'

const PaymentIndex = ({
  payment_id = '',
}: RouteComponentProps<{ payment_id: string }>) => {
  const {
    isTradeProject,
    payment,
    loading,
    updatePaymentAndRefetch,
    loadingUpdate,
    primaryOwnerEmail,
  } = usePublicPayment(payment_id)

  if (loading) return <Loader />

  const onPay = async (
    paymentSource?: PaymentSource,
    paymentMethod?: PaymentMethodType,
  ) => {
    const isPaymentMethodCard = paymentMethod === PaymentMethodType.CREDIT_CARD
    const isPaymentMethodInvoice = paymentMethod === PaymentMethodType.INVOICE
    const stripeSourceId = isPaymentMethodCard ? paymentSource?.id : null

    await updatePaymentAndRefetch({
      variables: {
        data: {
          metadata: {
            ...payment?.metadata,
            method: paymentMethod,
            ...(isPaymentMethodInvoice && {
              generateInvoiceAt: new Date(),
            }),
          },
          ...(isPaymentMethodInvoice && {
            status: 'pending',
          }),
          stripeSourceId,
        },
        where: { id: payment?.id ?? '' },
      },
      onCompleted: () => {
        if (payment?.metadata.type === PaymentType.DESIGN_DEPOSIT) {
          fireEvent({
            type: 'deposit_completed',
          })
        } else if (payment?.metadata.type === PaymentType.PROJECT_DEPOSIT) {
          fireEvent({
            type: 'project_deposit_completed',
          })
        }
      },
    })
  }

  const renderChild = () => {
    if (payment?.metadata.type === PaymentType.DESIGN_DEPOSIT) {
      return (
        <DesignDeposit
          isPublic
          isTradeProject={isTradeProject}
          loading={loadingUpdate}
          onPay={onPay}
          payment={payment}
          primaryOwnerEmail={primaryOwnerEmail}
        />
      )
    }
    if (payment?.metadata.type === PaymentType.PROJECT_DEPOSIT) {
      return (
        <ProjectDeposit
          isPublic
          isTradeProject={isTradeProject}
          loading={loadingUpdate}
          onPay={onPay}
          payment={payment}
          primaryOwnerEmail={primaryOwnerEmail}
        />
      )
    }
    return (
      <OtherPayment
        isPublic
        loading={loadingUpdate}
        onPay={onPay}
        payment={payment as Payment}
        primaryOwnerEmail={primaryOwnerEmail}
      />
    )
  }

  return payment?.status === 'succeeded' ? (
    <ThankYou
      imageUrl={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/payment.svg`}
      mainButton={
        <Button
          kind="solid"
          text="Go to Dashboard"
          fontAwesomeIcon="arrow-right-long"
          iconAnimation="right"
          onClick={() => navigate(`/app/projects/${payment?.project?.id}`)}
        />
      }
      subtitle="Your payment has been received. Your designer will reach out shortly to talk about next steps."
    />
  ) : (
    <AppLayout header={<FormHeader />}>{renderChild()}</AppLayout>
  )
}

export default PaymentIndex
