import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid, Image, List } from 'semantic-ui-react'

import SamplesOrderStatus from 'components/app/project/order-samples/status'
import StepTitleRow from 'components/app/step-title'
import FormHeader from 'components/shared/header-form'
import HelpMessage from 'components/shared/help-message'
import { getAddressString } from 'context/address/utils'
import OrderProvider from 'context/order/provider'
import useOrder from 'context/order/use'
import * as checkoutUtils from 'context/order/utils-checkout'
import AppLayout from 'design-system/containers/app-layout'
import { FIND_UNIQUE_ORDER_SAMPLES_CONTEXT } from 'queries/order'
import { FormList } from 'styles/app/components/list'
import { FormDivider } from 'styles/app/system'

const Module = () => {
  const { order } = useOrder()
  if (!order.placedAt) return null

  return (
    <AppLayout header={<FormHeader />}>
      <Grid stackable>
        <StepTitleRow title="Order Samples" />
        <Grid.Row centered style={{ padding: 0 }}>
          <Grid.Column computer={9} tablet={14} mobile={16}>
            <HelpMessage
              closeIcon
              icon="bell-exclamation"
              message="Our samples arrive covered with a protective film. Please remove it once you receive your samples."
              title="Important"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal" centered>
          <Grid.Column computer={6} tablet={16} mobile={16}>
            <FormList>
              <List.Item>
                <List.Content>
                  <p className="caption">Order Status</p>
                </List.Content>
              </List.Item>
              <FormDivider style={{ margin: '0 0 20px' }} />
              <SamplesOrderStatus order={order} />
              <FormDivider style={{ margin: '20px 0' }} />
              {order.nobiliaSamples?.map((s) => {
                const sample = checkoutUtils.nobiliaSampleToPaymentItem(s)
                return (
                  <List.Item key={s.id}>
                    <List.Content floated="right" verticalAlign="middle">
                      <p
                        style={{ lineHeight: '43px' }}
                      >{`x${sample.quantity}`}</p>
                    </List.Content>
                    <List.Icon verticalAlign="middle">
                      <Image
                        src={sample.imageUrl}
                        style={{ margin: '0', maxWidth: '30px' }}
                      />
                    </List.Icon>
                    <List.Content>
                      <p style={{ lineHeight: '43px' }}>{sample.name}</p>
                    </List.Content>
                  </List.Item>
                )
              })}
              <FormDivider style={{ margin: '16px 0' }} />
              <List.Item>
                <List.Content>
                  <p className="caption">Shipping Address</p>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <p>{getAddressString(order?.shippingAddress)}</p>
                </List.Content>
              </List.Item>
            </FormList>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default ({ order_id }: RouteComponentProps<{ order_id: string }>) => (
  <OrderProvider query={FIND_UNIQUE_ORDER_SAMPLES_CONTEXT} order_id={order_id}>
    <Module />
  </OrderProvider>
)
