import React from 'react'

import { RouteComponentProps } from '@reach/router'

const FkIndex = (
  props: RouteComponentProps & { children: React.ReactNode },
) => {
  return <>{props.children}</>
}

export default FkIndex
