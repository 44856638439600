import { useMutation, useQuery } from '@apollo/client'

import { notifyError } from 'context/notifications/trigger'
import {
  FindUniquePaymentPayload,
  FindUniquePaymentVariables,
  FIND_UNIQUE_PAYMENT,
  UpdatePaymentPayload,
  UpdatePaymentVariables,
  UPDATE_PAYMENT,
} from 'queries/payment'
import { ProjectType } from 'types/project'

const usePublicPayment = (paymentId: string) => {
  const { data, loading, refetch } = useQuery<
    FindUniquePaymentPayload,
    FindUniquePaymentVariables
  >(FIND_UNIQUE_PAYMENT, {
    variables: {
      where: {
        id: paymentId,
      },
    },
  })
  const payment = data?.payment

  const [updatePaymentAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdatePaymentPayload,
    UpdatePaymentVariables
  >(UPDATE_PAYMENT, {
    onCompleted: () => {
      refetch()
    },
    onError: ({ message }) => {
      notifyError(message)
    },
  })

  const project = payment?.project
  const isTradeProject = project?.data?.projectType === ProjectType.TRADE
  const primaryOwnerEmail = project?.owners?.find((o) => o.isPrimary)?.user
    ?.email

  return {
    loading,
    loadingUpdate,
    payment,
    refetch,
    updatePaymentAndRefetch,
    isTradeProject,
    primaryOwnerEmail,
  }
}

export default usePublicPayment
