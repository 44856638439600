import { gql } from '@apollo/client'

import { Payment, PaymentMethodType, PaymentType } from 'types/payment'

export const CREATE_PAYMENT = gql`
  mutation createPayment($data: PaymentCreateInput!) {
    createOnePayment(data: $data) {
      id
      amount
      description
      metadata
      refundAmount
      status
      stripeChargeId
      stripeInvoiceId
      stripeSourceId
    }
  }
`

export interface CreatePaymentPayload {
  createOnePayment: Payment
}

export interface CreatePaymentVariables {
  data: {
    amount: number
    description?: string
    design?: {
      connect?: { id: string }
    }
    metadata: {
      method?: PaymentMethodType
      stripeReaderId?: string
      type: PaymentType
    }
    order?: {
      connect: { id: string }
    }
    project?: {
      connect: { id: string }
    }
    prospect?: {
      connect: { id: string }
    }
    room?: {
      connect: { id: string }
    }
    refundAmount?: number
    status?: 'succeeded' | 'pending' | 'failed'
    stripeSourceId?: string
  }
}

export const DELETE_PAYMENT = gql`
  mutation deletePayment($where: PaymentWhereUniqueInput!) {
    deleteOnePayment(where: $where) {
      id
    }
  }
`

export interface DeletePaymentPayload {
  deleteOnePayment: Payment
}

export interface DeletePaymentVariables {
  where: { id: string }
}

export const FETCH_PAYMENTS = gql`
  query fetchPayments($where: PaymentWhereInput) {
    payments: findManyPayment(where: $where) {
      id
      amount
      description
      metadata
      refundAmount
      status
      stripeCharge
      stripeChargeId
      stripeInvoiceId
      stripeSourceId
    }
  }
`

export interface FetchPaymentsPayload {
  payments: Payment[]
}

export interface FetchPaymentsVariables {
  where: {
    project: {
      id: { equals: string }
    }
  }
}

export const FIND_UNIQUE_PAYMENT = gql`
  query findUniquePayment($where: PaymentWhereUniqueInput!) {
    payment: findUniquePayment(where: $where) {
      id
      amount
      description
      metadata
      status
      project {
        id
        data
        owners {
          isPrimary
          user {
            email
          }
        }
      }
      room {
        id
        data
        type
      }
    }
  }
`

export interface FindUniquePaymentPayload {
  payment: Payment
}

export interface FindUniquePaymentVariables {
  where: {
    id: string
  }
}

export const UPDATE_PAYMENT = gql`
  mutation updatePayment(
    $data: PaymentUpdateInput!
    $where: PaymentWhereUniqueInput!
  ) {
    updateOnePayment(data: $data, where: $where) {
      id
      amount
      description
      metadata
      refundAmount
      status
      stripeChargeId
      stripeInvoiceId
      stripeSourceId
    }
  }
`

export interface UpdatePaymentPayload {
  updateOnePayment: Payment
}

export interface UpdatePaymentVariables {
  data: {
    amount?: number
    description?: string
    metadata?: {
      method?: PaymentMethodType
      name?: string
      refundDate?: string
      refundNote?: string
      sendPaymentLinkAt?: Date
      stripePaymentIntentCaptureAt?: Date
      stripePaymentIntentId?: string
      stripePaymentIntentProcessAt?: Date
      stripePaymentIntentStatus?: string
      stripeReaderId?: string
      stripeReaderPresentPaymentAt?: Date
      stripeReaderResetAt?: Date
      stripeReaderStatus?: string
      stripeRefreshAt?: Date
      type?: PaymentType
    }
    refundAmount?: number
    status?: 'succeeded' | 'pending' | 'failed'
    stripeSourceId?: string | null
    stripeChargeId?: string
    stripeInvoiceId?: string
  }
  where: { id: string }
}
