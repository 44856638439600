import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import Loader from 'components/shared/loader'
import { useUniqueMeeting } from 'context/meeting/use-unique'
import { isMeetingNoShow, isMeetingScheduled } from 'context/meeting/utils'
import Button from 'design-system/components/button/button'
import ThankYou from 'design-system/containers/thank-you/thank-you'

import { PublicNoMeeting } from './no-meeting'
import { PublicMeetingBooker } from './public-meeting-booker'

const PublicMeetingIndex = ({
  meeting_id: meetingId,
}: RouteComponentProps<{ meeting_id: string }>) => {
  const { meeting, loading, startPolling, stopPolling } = useUniqueMeeting(
    meetingId ?? '',
    { shallow: true },
  )

  if (loading) return <Loader />

  if (!meeting) return <PublicNoMeeting />

  if (isMeetingScheduled(meeting) || isMeetingNoShow(meeting))
    return (
      <ThankYou
        title="Meeting Scheduled"
        lateralText="Meeting Scheduled"
        subtitle="Log into your dashboard to see more meetings."
        mainButton={
          <Button
            kind="solid"
            text="Go to dashboard"
            fontAwesomeIcon="arrow-right"
            iconAnimation="right"
            onClick={() => navigate('/login-user')}
          />
        }
        imageUrl={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/schedule-call.svg`}
      />
    )

  //* Not scheduled or canceled
  return (
    <PublicMeetingBooker
      meeting={meeting}
      startPolling={startPolling}
      stopPolling={stopPolling}
    />
  )
}

export default PublicMeetingIndex
