import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import { isMeetingScheduled } from 'context/meeting/utils'
import { Breakpoints } from 'styles/home/system'
import { Meeting } from 'types/meeting'

type PublicMeetingBookerProps = {
  handleMeetingScheduled?: () => void
  meeting: Meeting
  startPolling: (interval: number) => void
  stopPolling: () => void
}

export const PublicMeetingBooker = ({
  meeting,
  startPolling,
  stopPolling,
}: PublicMeetingBookerProps) => {
  const [isPolling, setIsPolling] = useState(false)

  useEffect(() => {
    if (!meeting || isMeetingScheduled(meeting)) {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [meeting])

  const handleEventScheduled = () => {
    setIsPolling(true)
    startPolling(2000)
  }

  return (
    <BookerContainer>
      <h2>Find a time</h2>
      {isPolling && (
        <p className="no-margin">
          Scheduled successfully. You'll be redirected shortly
        </p>
      )}
      <CalendlyInlineScheduler
        calendlyUrl={meeting.calendlyUrl}
        meetingId={meeting.id}
        onEventScheduled={handleEventScheduled}
        type={meeting.metadata.type}
      />
    </BookerContainer>
  )
}

const BookerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-top: 64px;

  @media ${Breakpoints.onlyMobile} {
    padding-top: 32px;
  }
`
