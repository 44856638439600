import React from 'react'

import { navigate } from 'gatsby'

import HeaderContainer from 'design-system/containers/header'
import { FormLogoItem } from 'design-system/containers/header/header'

const FormHeader = ({ rightItem }: { rightItem?: React.ReactNode }) => {
  return (
    <HeaderContainer
      leftItems={<FormLogoItem onClick={() => navigate('/')} />}
      rightItems={rightItem ?? null}
    />
  )
}

export default FormHeader
