import React from 'react'

import { navigate } from 'gatsby'
import styled from 'styled-components'

import Button from 'design-system/components/button/button'
import { Colors } from 'styles/app/system'
import { Breakpoints } from 'styles/home/system'

export const PublicNoMeeting = () => {
  return (
    <StyledContainer>
      <h1>Oops!</h1>
      <h3>
        Looks like you link is invalid. Please contact your designer to schedule
        your next meeting.
      </h3>
      <Button
        kind="solid"
        text="Go to dashboard"
        fontAwesomeIcon="arrow-right"
        iconAnimation="right"
        onClick={() => navigate('/login-user')}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  align-items: center;
  background-color: ${Colors.gray900};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 0 20%;
  row-gap: 32px;
  text-align: center;

  & > h1,
  h3 {
    color: ${Colors.white};
    margin: 0;
  }

  @media ${Breakpoints.onlyMobile} {
    padding: 0 16px;
  }
`
