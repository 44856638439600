import React from 'react'

import * as Sentry from '@sentry/browser'
import { Container } from 'semantic-ui-react'

import Button from 'design-system/components/button'

interface ErrorBoundaryProps {
  children?: React.ReactNode
}
interface ErrorBoundaryState {
  error: Error | null
  errorInfo: React.ErrorInfo | null
  eventId: string | null
  hasError: boolean | null
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
      eventId: null,
      hasError: false,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught exception', { error, errorInfo })
    Sentry.withScope((scope) => {
      scope.setExtras({ ...errorInfo })
      const eventId = Sentry.captureException(error)
      this.setState({ eventId, errorInfo })
    })
  }

  render() {
    if (this.state.hasError) {
      const href = window.location.pathname.includes('app') ? '/app' : '/admin'
      return (
        <div style={{ padding: '30vh 0' }}>
          <Container textAlign="center">
            <h2>Oops! We encountered an error.</h2>
            <p>Please contact help@formkitchens.com if it persists.</p>
            <br />
            <a href={href}>
              <Button kind="solid" text="Go Home" />
            </a>
          </Container>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
