import { gql } from '@apollo/client'

import { NobiliaSampleGraphQLCreateInput } from 'types/nobilia-sample'
import { Order, OrderData, OrderType } from 'types/order'
import { PaymentItem, PaymentMethodType, PaymentType } from 'types/payment'
import { ProjectStaffRole } from 'types/project-staff'
import { Address } from 'types/utils'

export const CABINETRY_ORDER_CONTEXT_FRAGMENT = gql`
  fragment CabinetryOrderContext on Order {
    id
    designs {
      id
      countertopEstimate {
        id
        key
        metadata
        name
        numPages
      }
      issuesResolved {
        id
        data
        files {
          id
          approvedAt
          createdAt
          key
          name
          numPages
          type
        }
        project {
          id
        }
        resolutionType
        type
      }
      metadata
      name
      orderId
      plans {
        id
        approvedAt
        createdAt
        key
        name
        type
      }
      renders {
        id
        key
        metadata
        name
        type
      }
      room {
        id
        createdAt
        data
        files {
          id
          createdAt
          key
          name
          numPages
          type
        }
        type
      }
      supplierOrders {
        id
        actions {
          id
          data
        }
        createdAt
        data
        delivery {
          id
          data
          date
        }
        deliveryNote {
          id
          key
          name
        }
        issuesResolved {
          id
          data
          files {
            id
            approvedAt
            createdAt
            key
            name
            numPages
            type
          }
          project {
            id
          }
          resolutionType
          type
        }
        nobiliaAckNumber
        nobiliaOrderRef
        project {
          id
        }
        route {
          id
          archivedAt
          color
          confirmedAt
          data
          reference
        }
        shipment {
          id
          archivedAt
          color
          confirmedAt
          data
          reference
        }
      }
    }
    discounts
    files {
      id
      createdAt
      key
      name
      numPages
      type
    }
    metadata
    payments {
      id
      amount
      createdAt
      description
      metadata
      refundAmount
      room {
        id
        data
        type
      }
      status
      stripeCharge
      stripeSourceId
    }
    placedAt
    referenceNumber
    shippingAddress
    targetDate
    taxRate
    type
  }
`

// for global tables
export const CABINETRY_ORDER_FIND_MANY_FRAGMENT = gql`
  fragment CabinetryOrderFindMany on Order {
    ...CabinetryOrderContext
    project {
      id
      archivedAt
      data
      owners {
        isSubscribed
        isPrimary
        user {
          id
          email
          firstName
          lastName
          picture
          type
        }
        userRole
      }
      projectAddress
      shippingAddress
      staff {
        data
        isSubscribed
        isPrimary
        staffMember {
          id
          email
          firstName
          lastName
          picture
          type
        }
        staffMemberRole
      }
    }
  }
`

// for in project context
export const SAMPLES_ORDER_CONTEXT_FRAGMENT = gql`
  fragment SamplesOrderContext on Order {
    id
    discounts
    metadata
    nobiliaSamples {
      id
      quantity
      productClass {
        id
        description
        imageUrl
        productClassType
      }
    }
    payments {
      id
      amount
      description
      metadata
      refundAmount
      status
      stripeCharge
      stripeSourceId
      createdAt
    }
    placedAt
    referenceNumber
    shippingAddress
    targetDate
    taxRate
    type
  }
`

// for global tables
export const SAMPLES_ORDER_FIND_MANY_FRAGMENT = gql`
  fragment SamplesOrderFindMany on Order {
    ...SamplesOrderContext
    project {
      id
      archivedAt
      data
      owners {
        isSubscribed
        isPrimary
        user {
          id
          email
          firstName
          lastName
          picture
          type
        }
        userRole
      }
      projectAddress
      shippingAddress
      staff {
        data
        isSubscribed
        isPrimary
        staffMember {
          id
          email
          firstName
          lastName
          picture
          type
        }
        staffMemberRole
      }
    }
    prospect {
      id
      data
      email
      firstName
      lastName
      phone
      staff {
        data
        isSubscribed
        isPrimary
        staffMember {
          id
          data
          email
          firstName
          lastName
          picture
          type
        }
        staffMemberId
        staffMemberRole
      }
    }
  }
`

// for create update modals out of context
export const SAMPLES_ORDER_WITH_PROJECT_AND_PROSPECT_FRAGMENT = gql`
  fragment SamplesOrderWithProjectAndProspect on Order {
    ...SamplesOrderContext
    project {
      id
      archivedAt
      data
      owners {
        isSubscribed
        isPrimary
        user {
          id
          email
          firstName
          lastName
          picture
          type
        }
        userRole
      }
      projectAddress
      rooms(orderBy: { id: asc }) {
        id
        createdAt
        data
        designs {
          id
          createdAt
          metadata
          name
        }
        type
      }
      shippingAddress
    }
    prospect {
      id
      data
      email
      firstName
      lastName
      phone
      staff {
        data
        isSubscribed
        isPrimary
        staffMember {
          id
          data
          email
          firstName
          lastName
          picture
          type
        }
        staffMemberId
        staffMemberRole
      }
    }
  }
`

export const CREATE_ORDER = gql`
  mutation createOrder($data: OrderCreateInput!) {
    createOneOrder(data: $data) {
      id
    }
  }
`

export interface CreateOrderPayload {
  createOneOrder: { id: string }
}

export interface CreateOrderVariables {
  data: {
    designs?: {
      connect?: { id?: string }[]
    }
    discounts?: PaymentItem[]
    files?: {
      connect?: { id: string }[]
    }
    metadata?: OrderData
    nobiliaSamples?: {
      create?: NobiliaSampleGraphQLCreateInput[]
    }
    payments?: {
      connect: { id?: string }[]
    }
    name?: string
    placedAt?: Date | string
    project?: { connect: { id: string } }
    prospect?: { connect: { id: string } }
    shippingAddress?: Address
    taxRate?: number
    type?: OrderType
  }
}

export const DELETE_ORDER = gql`
  mutation deleteOrder($where: OrderWhereUniqueInput!) {
    deleteOneOrder(where: $where) {
      id
    }
  }
`

export interface DeleteOrderPayload {
  deleteOneOrder: Order
}

export interface DeleteOrderVariables {
  where: { id: string }
}

export const FIND_UNIQUE_ORDER_CABINETRY_CONTEXT = gql`
  query findUniqueOrderCabinetryContext($where: OrderWhereUniqueInput!) {
    order: findUniqueOrder(where: $where) {
      ...CabinetryOrderContext
    }
  }
  ${CABINETRY_ORDER_CONTEXT_FRAGMENT}
`

export const FIND_UNIQUE_ORDER_SAMPLES_CONTEXT = gql`
  query findUniqueOrderSamplesContext($where: OrderWhereUniqueInput!) {
    order: findUniqueOrder(where: $where) {
      ...SamplesOrderContext
    }
  }
  ${SAMPLES_ORDER_CONTEXT_FRAGMENT}
`

export const FIND_UNIQUE_ORDER_SAMPLES_WITH_PROJECT_AND_PROSPECT = gql`
  query findUniqueOrderSamplesContext($where: OrderWhereUniqueInput!) {
    order: findUniqueOrder(where: $where) {
      ...SamplesOrderWithProjectAndProspect
    }
  }
  ${SAMPLES_ORDER_WITH_PROJECT_AND_PROSPECT_FRAGMENT}
  ${SAMPLES_ORDER_CONTEXT_FRAGMENT}
`

export const FIND_UNIQUE_ORDER_LOOKUP = gql`
  query findUniqueOrderLookup($where: OrderWhereUniqueInput!) {
    order: findUniqueOrder(where: $where) {
      id
      project {
        id
      }
    }
  }
`

export interface FindUniqueOrderPayload {
  order: Order
}

export interface FindUniqueOrderVariables {
  where: {
    id: string
  }
}

export const FIND_MANY_ORDER_CABINETRY = gql`
  query findManyOrderCabinetry(
    $take: Int
    $orderBy: [OrderOrderByWithRelationInput!]
    $skip: Int
    $where: OrderWhereInput
  ) {
    count: findManyOrderCount(where: $where)
    orders: findManyOrder(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      ...CabinetryOrderFindMany
    }
  }
  ${CABINETRY_ORDER_FIND_MANY_FRAGMENT}
  ${CABINETRY_ORDER_CONTEXT_FRAGMENT}
`

export const FIND_MANY_ORDER_SAMPLES = gql`
  query findManyOrderSamples(
    $take: Int
    $orderBy: [OrderOrderByWithRelationInput!]
    $skip: Int
    $where: OrderWhereInput
  ) {
    count: findManyOrderCount(where: $where)
    orders: findManyOrder(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      ...SamplesOrderFindMany
    }
  }
  ${SAMPLES_ORDER_FIND_MANY_FRAGMENT}
  ${SAMPLES_ORDER_CONTEXT_FRAGMENT}
`

export const FIND_MANY_ORDER_COUNT = gql`
  query findManyOrderCount($where: OrderWhereInput) {
    count: findManyOrderCount(where: $where)
  }
`

export interface FindManyOrderCountPayload {
  count: number
}

export interface FindManyOrderPayload {
  orders: Order[]
  count?: number
}

export interface FindManyOrderVariables {
  orderBy?: {
    placedAt?: string
  }
  skip?: number
  take?: number
  where?: {
    id?: {
      in?: string[]
    }
    derivedSearch?: {
      contains: string
      mode?: 'insensitive'
    }
    metadata?: {
      path: string[]
      equals: string | boolean
    }
    NOT?: {
      designs?: {
        some: {
          name: {
            startsWith: string
          }
        }
      }
    }[]
    placedAt?: {
      not: null
    } | null
    project?: {
      id?: {
        equals?: string
        in?: string[]
      }
      archivedAt?: { not?: string | null } | null
      staff?: {
        some?: {
          staffMemberId?: {
            equals: string
          }
          isSubscribed?: {
            equals: boolean
          }
          staffMemberRole?: {
            equals: ProjectStaffRole
          }
        }
      }
    }
    prospect?: {
      id: {
        equals: string
      }
    }
    shipment?: {
      id: {
        equals: string
      }
    }
    type?: {
      equals?: OrderType
    }
  }
}

export const UPDATE_ORDER = gql`
  mutation updateOrder(
    $where: OrderWhereUniqueInput!
    $data: OrderUpdateInput!
  ) {
    updateOneOrder(where: $where, data: $data) {
      id
      metadata
      shippingAddress
      taxRate
    }
  }
`

export interface UpdateOrderPayload {
  updateOneOrder: Order
}

export interface UpdateOrderVariables {
  data: {
    designs?: { connect?: { id: string }[]; set?: { id: string }[] }
    discounts?: PaymentItem[]
    files?: { connect?: { id: string }[]; set?: { id: string }[] }
    name?: string
    metadata?: NullableRecord<OrderData>
    nobiliaSamples?: {
      upsert?: {
        where: {
          id: string
        }
        update: NobiliaSampleGraphQLCreateInput
        create: NobiliaSampleGraphQLCreateInput
      }[]
      delete?: { id: string }[]
    }
    placedAt?: Date | string | null
    payments?: {
      connect?: { id?: string }[]
      create?: {
        amount?: number
        description?: string
        status?: 'succeeded' | 'pending' | 'failed'
        stripeSourceId?: string | null
        metadata?: {
          type?: PaymentType
          name?: string
          method?: PaymentMethodType
        }
        project?: { connect?: { id?: string } }
        refundAmount?: number
      }[]
      set?: { id?: string }[]
    }
    project?: {
      connect: {
        id: string
      }
    }
    referenceNumber?: string
    shippingAddress?: Address
    targetDate?: Date | string
    taxRate?: number
  }
  where: {
    id: string
  }
}
