import React from 'react'

import { Router } from '@reach/router'

import ErrorBoundary from 'components/shared/error-boundary'
import SemanticUIFull from 'components/shared/semantic-ui-full'
import SEO from 'components/shared/seo'
import Tracking from 'components/shared/tracking'
import { StyledContent } from 'styles/app/system'
import Index from 'views/_fk'
import PublicMeetingIndex from 'views/_fk/meetings'
import Payment from 'views/_fk/payment'
import SamplesOrder from 'views/_fk/samples-order'

const FormKitchens = () => {
  const isEnvTracked =
    process.env.GATSBY_ACTIVE_ENV === 'production' ||
    process.env.GATSBY_ACTIVE_ENV === 'stage'
  return (
    <StyledContent>
      <SemanticUIFull />
      <Tracking segment={!!isEnvTracked} />
      <ErrorBoundary>
        <Router>
          <Index path="/_fk">
            <Payment path="payments/:payment_id" />
            <SamplesOrder path="sample-orders/:order_id" />
            <PublicMeetingIndex path="meetings/:meeting_id" />
          </Index>
        </Router>
      </ErrorBoundary>
    </StyledContent>
  )
}

export const Head = () => (
  <SEO title="FORM Kitchens" description="FORM Kitchens" />
)

export default FormKitchens
