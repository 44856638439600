import React, { createContext } from 'react'

import { DocumentNode, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_ORDER_CABINETRY_CONTEXT,
  FindUniqueOrderPayload,
  FindUniqueOrderVariables,
} from 'queries/order'
import { Order } from 'types/order'

interface IOrderContext {
  order: Order
  refetch: () => Promise<any>
}

interface OrderProps
  extends RouteComponentProps<{ order_id: string; noLoader?: boolean }> {
  query?: DocumentNode
  children: React.ReactNode
}

export const OrderContext = createContext<IOrderContext>({} as IOrderContext)

const OrderProvider = (props: OrderProps) => {
  const { loading, data, refetch } = useQuery<
    FindUniqueOrderPayload,
    FindUniqueOrderVariables
  >(props.query || FIND_UNIQUE_ORDER_CABINETRY_CONTEXT, {
    variables: {
      where: { id: props.order_id ?? '' },
    },
  })

  if (loading || !data) return props.noLoader ? null : <Loader />
  const order = data?.order

  return (
    <OrderContext.Provider
      value={{
        order,
        refetch,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  )
}

export default OrderProvider
